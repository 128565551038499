import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";

import locales from "../../../constants";

const SpokojWGlowie = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "en";
  return (
    <Layout
      seo={{
        title: "Spokoj w głowie",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/spokoj_og.jpeg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #F8B030, #D89A2E, #DDA33B, #F5AB29, #FFC04D )",
        icons: "#f8b030",
        navClass: "spokoj__nav",
        ogImage: require("../../../assets/img/portfolio/spokoj_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/spokoj-w-glowie/",
      }}
    >
      <PortfolioHeader name="spokoj" height="260" />
      <div className="spokoj">
        <section
          className="container-fluid portfolio-intro spokoj-section-2"
          id="info"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Spokój w głowie</h1>
                <ul>
                  <li>Website</li>
                  <li>Logo</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner inner--divider">
                <p>
                We were responsible for a full rebranding of psychological clinic "Spokój w Głowie" (EN: Peace of mind). We have created a new logo and website with a booking system for the patients.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="spokoj-section-3">
          <div className="inner">
            <h2>Logo</h2>
            <p>
            We created a completely new graphic symbol for the logo. The sign was supposed not to directly refer to the human's head, but to be an emotional association with the name of the clinic. A new sign was created on the basis of the guidelines, which consists of a signet showing the sunrise over the surface of calm water, and simple sans serif typography.
            </p>
          </div>
          <div className="logo-presentation">
            <div className="inner">
              <div className="logo_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/spokoj_logo_old.png")}
                  alt=""
                />
                <span>Old logo</span>
              </div>
              <div className="logo_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/spokoj_logo_new.svg")}
                  alt=""
                />
                <span>New logo</span>
              </div>
            </div>
          </div>
        </section>
        <section className="spokoj-section-4">
          <div className="laptop_wrapper">
            <div className="img_wrapper">
              <img
                src={require("../../../assets/img/portfolio/spokoj_full_page.png")}
                alt=""
              />
            </div>
          </div>
        </section>
        <section className="spokoj-section-5">
          <div className="inner">
            <h2>Website</h2>
            <p>
            We have created a new website for the psychological clinic "Spokój w Głowie" (EN: Peace of mind). The main goal of the project was to design and code a modern and fast website with a booking system for the patients of the clinic. The key aspect was to maintain the historically worked out SEO rankings, because the old website already had top positions in search results, which had been built for years. In the end we have managed to achieve all the goals set for the website without losing any of the aforementioned positions.
            </p>
          </div>
          <div className="image-group">
            <div>
              <img
                src={require("../../../assets/img/portfolio/spokoj_main_2.png")}
                alt=""
              />
            </div>
            <div>
              <img
                src={require("../../../assets/img/portfolio/spokoj_main_1.png")}
                alt=""
              />
            </div>
          </div>
          <div className="image-group-last">
            <div>
              <img
                src={require("../../../assets/img/portfolio/spokoj_main_4.png")}
                alt=""
              />
            </div>
            <div>
              <img
                src={require("../../../assets/img/portfolio/spokoj_main_3.png")}
                alt=""
              />
            </div>
          </div>
          <img
            className="iphone"
            src={require("../../../assets/img/portfolio/spokoj_iphone.png")}
            alt=""
          />
        </section>
      </div>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default SpokojWGlowie;
